import config from 'Data';
import React from 'react';
import { Layout, Head } from 'Common';
import ThanksContent from 'Components/ContactPage/ThanksContent';

const Thanks = () => (
  <Layout>
    <Head type="Organization" location="/thanks">
      Thanks! | {config.legalName}
    </Head>
    <ThanksContent />
  </Layout>
);

export default Thanks;
