import React from 'react';
import { Link } from 'gatsby';
import { ThemeContext } from 'Common';

import {
  Button,
  Collapse,
  Jumbotron,
  Container,
  Row,
  Col,
  H1,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown
} from 'reactstrap';

export default () => (
  <ThemeContext.Consumer>
    {({ theme }) => (
      <Container className="my-5 py-5">
        <Row>
          <Col>
            <Jumbotron className="text-center bg-lighter">
              <h1 className="text-black">Thank you!</h1>
              <p>We'll get back to you as soon as possible!</p>
              <Link to="/">Go Back Home</Link>
            </Jumbotron>
          </Col>
        </Row>
      </Container>
    )}
  </ThemeContext.Consumer>
);
